import React from "react";
import Table from 'react-bootstrap/Table';
// import { AiOutlineCalendar } from "react-icons/ai";
import Navbar from './navbar.js'
import Total from './total.js'
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { useEffect, useState, useRef } from "react";

const baseUrl = process.env.REACT_APP_BASE_URL;
const callActionEndPoint = process.env.REACT_APP_GETCALLACTION_ENDPOINT
const userDataString = localStorage.getItem('login_user_id');
const userDataisAdmin= localStorage.getItem('login_user_is_admin');

const Phone1 = () => {

    const [data, setData] = useState([]);
    const isMounted = useRef(true);



    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }


    useEffect(() => {
        if (isMounted.current) {
            console.log("Inside useEffect");

            const fetchData = async () => {
                try {
                    const login_user_id = JSON.parse(userDataString);  
                    const data = {
                        user_id:login_user_id.user_id,
                        isAdmin: JSON.parse(userDataisAdmin).isAdmin 
                      };   
                    const query = 'F-1';
                    const result = await axios.post(`${baseUrl}/${callActionEndPoint}?action=${query}`,JSON.stringify(data) , config);
                    if(result.data.data == null){
                        console.log("no data found.")
                    }else{
                        setData(result.data.data);
                    }  
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            };
            isMounted.current = false;
            fetchData();
        }

        
    }, []);

    return (

        <div className="container-fluid">

            <div className="row">
                <div className="col-sm-2"></div>
                <div className="col-sm-10">
                    {/* main navbar */}
                    <Navbar />

                    {/* totalbox */}
                    <Total />

                    <div className="container-fluid mt-5">
                        <h3 className="table-Title">Phone-1</h3>
                        <Table responsive className="All-table">
                            <thead className="table-head">
                                <tr>
                                    <th>SR</th>
                                    <th>DATE</th>
                                    <th>NAME</th>
                                    <th>EMAIL ID</th>
                                    <th>MOBILE NUMBER</th>
                                    <th>AREA</th>
                                    <th>EDUCATION</th>
                                    <th>SOURCE</th>
                                    <th>COURSE</th>
                                    <th>NARRATION</th>
                                    <th>STAGE </th>
                                </tr>
                            </thead>

                            <tbody>

                                {Object.values(data).map((val, key) => {
                                    const studentId = val.studentId[0];
                                    return <tr key={key}>
                                        <td>{key + 1}</td>
                                        <td>{new Date(val.createdAt).toLocaleDateString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric' })}</td>
                                        {/* <td>{val.createdAt}</td> */}
                                        <td>{studentId.name}</td>
                                        <td>{studentId.email}</td>
                                        <td>{studentId.mobile}</td>
                                        <td>{studentId.address}</td>
                                        <td>{studentId.education}</td>
                                        <td>{studentId.source}</td>
                                        <td>{val.courses[0].course}</td>
                                        <td>{val.narration}</td>
                                        <td>{val.action}</td>
                                    </tr>
                                })}


                            </tbody>
                        </Table>



                    </div>
                </div>
            </div>




        </div>
    )
}

export default Phone1