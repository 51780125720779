import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import Sidebar from "../component/sidebar";
import auth from "../utils/auth";

const ProtectedRoutes = () => { 
  const authToken = auth(); 
  const isAuthenticated = authToken && authToken !== "";

  return (
    <div>
      {isAuthenticated ? (
        <>
          <Sidebar />
          <Outlet />
        </>
      ) : ( 
        <Navigate to="/" />
      )}
    </div>
  );
};
export default ProtectedRoutes;
