import { React, useState } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import Logoimg from '../images/blackLogo.png'
import Whitelogo from '../images/whiteLogo.png'

import Swal from 'sweetalert2';

import Image from '../images/loginpage-image.png'
import { MdLockOutline } from "react-icons/md";
import { HiOutlineMail } from "react-icons/hi";

const baseUrl = process.env.REACT_APP_BASE_URL;
const loginEndPoint = process.env.REACT_APP_LOGIN_ENDPOINT;


const Login = () => {

  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isAdmin,setisAdmin] = useState('')
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setpasswordError] = useState(null);


  const config = {
    headers: {
      'Content-Type': 'application/json',
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      setEmailError('email is require!.');
      return;
    }
    if (!password) {
      setpasswordError('password is require!.');
      return;
    }
    if (!validateEmail(email)) {
      setEmailError('Invalid email address.');
      return;
  }
  if (!ValidPassword(password)) {
    setpasswordError('Password should contain 8 characters!');
    return;
}
  
    await handleChange()

  }

  const validateEmail = (email) => {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
}
const ValidPassword = (password) => {
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  return regex.test(password);
}
const handleChange = async () => {
  const data = {
    email: email,
    password: password,
    isAdmin:"true"
  }; 
  try {
    const result = await axios.post(`${baseUrl}/${loginEndPoint}`, JSON.stringify(data), config);
   
    if (result.data.status === 200) { 
      localStorage.setItem('login_user_id', JSON.stringify({ user_id: result.data.data._id }));
      localStorage.setItem('login_user_data', JSON.stringify({ email: result.data.data.email }));
      localStorage.setItem('login_user_image', JSON.stringify({ image: result.data.data.image }));
      localStorage.setItem('login_user_is_admin', JSON.stringify({ isAdmin: result.data.data.isAdmin }));
      localStorage.setItem('token', result.data.data.token);

      Swal.fire({
        title: 'Login Successful!',
        confirmButtonText: 'Ok'
      }).then((result) => {
        if (result.isConfirmed) {

          navigate("/dashboard");
          window.location.reload()
        }
      });
    }
  } catch (error) {

    console.error("Error fetching data:", error);

    Swal.fire({
      title: 'Login Failed!',
      text: 'An error occurred during login. Please try again.',
      icon: 'error',
      confirmButtonText: 'Ok'
    });
  }
};

 


  return (

    <section className="vh-100 Login-main">
      <div className="container-fluid ps-0 ms-0">
        <div className="row">
        <div className='d-flex justify-content-center'><img className='mt-3 Logo' src={Whitelogo} /></div>
        {/* <div class="col-sm-6">
            <div><img className='mt-3 Logo' src={Logoimg} /></div>
            <img class="w-100 vh-100" src={Image} />
          </div> */}

          <div className="form-col-right">
            <div className="d-flex justify-content-around px-5 mt-5 pt-5 form-div  ">

              <form className='mt-5' onSubmit={(e) => handleSubmit(e)}   >
                <div>
                  <h1 className='welcome align-center mt-5'><b>WelCome</b></h1>
                  <p style={{ textAlign: "center" ,fontWeight:"900"}}>login with Email</p>
                </div>

                <fieldset className="border mb-">
                  <legend className="float-none w-auto email-input">Email Id:</legend>
                  <div className='icon-input-dv'>
                    <MdLockOutline style={{ color: "white", fontSize: "1.5em" }} />
                    <input type="text"
                      className='email'
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder='example@gmail.com..' />

                  </div>
                </fieldset>

                <fieldset className="border mb-3">
                  <legend className="float-none w-auto email-input" >Password:</legend>
                  <div className='icon-input-dv'>
                    <HiOutlineMail style={{ color: "white", fontSize: "1.5em" }} />

                    <input type="text"
                      className='email'
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder='*********' />
                  </div>
                </fieldset>

                <div className='d-flex align-items-center'></div>
                {/* login error */}
                {emailError && <h2 style={{ color: 'red', fontSize: "15px" }}>{emailError}</h2>}
                {passwordError && <h2 style={{ color: 'red', fontSize: "15px" }}>{passwordError}</h2>}


                <p className='mb-5 forgot-pass'><a href='#'>Forgot your password?</a></p>
                <div className='d-flex jusify-content-center'>
                  <input className="btn submit-btn"
                    type="submit"
                    onClick={(e) => handleSubmit(e)}
                    value="Login" />

                </div>


              </form>

            </div>
            <div className='d-flex justify-content-center footer '>© 2023 xwebinfolab. All rights reserved.</div>
          </div>
         
        </div>
      </div>
    </section>

  )
};

export default Login;


