import React from "react";
import Navbar from "./navbar.js";
import Middlenav from "./middlebar.js";
import Total from "./total.js";
import LineGraph from "./linechart.js";
import Piechart from "./piechart.js";
import AdminLineGraph from "./adminLinechart.js"

const Dash = (props) => {


    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-2"></div>
                    <div className="col-sm-10">
                        <Navbar />
                        <Middlenav />
                        <Total />
                        <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                 <LineGraph />
                                 {/* <AdminLineGraph/> */}
                                 </div>
                            <div className="col-sm-6">   <Piechart /></div>
                        </div>
                        </div>
                    </div>


                </div>

            </div>
        </>
    );
};

export default Dash