import React from "react";

const ErrorPage = () => {
    return (
        <>
        <div className="row">
        <div className="col-sm-2"></div>
        <div className="col-sm-10">
        <div className="container">
            <h1>YOU'RE STUCK ON HIGHWAY 404! (get it?)</h1>
            <p>Just trying to say you're not suppose to be here!</p>
        </div>
        </div>
        </div>
        </>
    )
}

export default ErrorPage;