import { React, useState } from "react";
import Logo from "../images/whiteLogo.png"
import { MdOutlineDoneAll, MdShop2, MdSpaceDashboard, MdPermPhoneMsg, MdPersonAddDisabled, MdPhoneLocked, MdPhoneForwarded, MdLogout, MdOutlineHowToReg, MdPendingActions } from "react-icons/md";
import { BsPencilFill } from "react-icons/bs";
import { AiFillSwitcher } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { IoReorderThree } from "react-icons/io5";
const userDataisAdmin= localStorage.getItem('login_user_is_admin');

const Sidebar = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const handleToggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate("/")

    };


    const menuItem = [
        {
            path: "/dashboard",
            name: "Dashboard",
            icon: <MdSpaceDashboard />,

        },
        {
            path: "/entry",
            name: "Entry",
            icon: <MdShop2 />,
        },
        {
            path: "/schedule",
            name: "schedule",
            icon: <AiFillSwitcher />,
        },
        {
            path: "/attend",
            name: "Attend",
            icon: <BsPencilFill />
        },

        {
            path: "/callAction",
            name: "CallAction",
            icon: <MdPendingActions />
        },
        {
            path: "/positive",
            name: "Positive",
            icon: <MdOutlineDoneAll />
        },
        {
            path: "/phone1",
            name: "F1",
            icon: <MdPhoneForwarded />
        },
        {
            path: "/phone2",
            name: "F2",
            icon: <MdPermPhoneMsg />
        },
        {
            path: "/phone3",
            name: "F3",
            icon: <MdPhoneLocked />
        },
        {
            path: "/enroll",
            name: "Enroll",
            icon: <MdOutlineHowToReg />
        },
        {
            path: "/reject",
            name: "Reject",
            icon: <MdPersonAddDisabled />
        },
        // {
        //     path: handleLogout(),
        //     name: "Logout",
        //     icon: <MdLogout />
        // }
    ]




    return (
        <>
            <div className={`col-sm-2 sidebar ${isSidebarOpen ? 'open' : ''}`}>
            
                <div className="top-section">
                    <img src={Logo} className="ms-3 mb-5 mt-2 logo-sidenav" />


                </div>
                <ul className="list-group">

                    {menuItem.map((val, key) => {
                        // console.log(val.path)
                        // onClick={() => { window.location.pathname = val.path }} 
                        return (
                            <li key={key}>
                                <Link to={val.path}>
                                    <div className="nav-link mb-2 ">{val.icon}
                                        <div className="index-name ms-2">{val.name} </div>
                                    </div>

                                </Link>
                            </li>

                        )
                    })
                    }
                    <li className="nav-link" onClick={handleLogout}><MdLogout /> Logout</li>
                </ul>
               
            </div>
            
            <button className="navicon" onClick={handleToggleSidebar}>
                <IoReorderThree />
            </button>
        </>
    );
};

export default Sidebar




