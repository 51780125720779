import React, { Component } from 'react';
import CanvasJSReact from '@canvasjs/react-charts';
import { useEffect, useState } from "react";
import axios from 'axios';


var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const baseUrl = process.env.REACT_APP_BASE_URL;
const allStudentLastMonth = process.env.REACT_APP_ALL_STUDENTS_FOR_LASTMONTH

const LineGraph = () => { 
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);

	const config = {
		headers: {
			'Content-Type': 'application/json'
		}
	}
	
	useEffect(() => {
		const tableList = async () => { 
			const result = await axios.get(`${baseUrl}/${allStudentLastMonth}`, config)
			if (result.data && result.data.data) {

				const formattedData = result.data.data.map(item => ({
					x: new Date(item.x), 
					y: parseInt(item.y), 
				  }));
				  setData(formattedData);
				  setLoading(false);
				   
			} else {
			console.log("loading fail")
				setLoading(false);
			}

		}		
		tableList()
	}, []) 

	 
	const options = {

		animationEnabled: true,
		theme: "dark1",
		title: {
			text: "students",

		},
		axisX: {
			title:"month",
			valueFormatString: "MMM"
		},
		axisY: {
			title: "student",
			prefix: "",
			gridThickness: 0,
		},
		
		data: [{
			yValueFormatString: "###",
			xValueFormatString: "MMMM",
			type: "spline",
			lineColor:"gold",
			markerColor:"gold",
			dataPoints: data,
		
		}],
	}

	return (
		
				<div className='mt-3'>
					
					<CanvasJSChart options={options}/>
				</div>
				
	

	);
}

export default LineGraph;

