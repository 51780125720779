import React from "react";
import Table from 'react-bootstrap/Table';
import Navbar from './navbar.js'
import Total from './total.js'
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { useEffect, useState,useRef} from "react";
import Swal from 'sweetalert2';


const baseUrl = process.env.REACT_APP_BASE_URL;
const enterStudentDetail = process.env.REACT_APP_STUDENTS_ENDPOINT;
const scheduleDate = process.env.REACT_APP_SCHEDULEDATE_ENDPOINT;

const userDataString = localStorage.getItem('login_user_id');
const userDataisAdmin= localStorage.getItem('login_user_is_admin');


const Schedule = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([])
  const[date,setDate]=useState("")
const isMounted = useRef(true)

  const handleChange = async () => { 
    if (!date.date) {
      Swal.fire({
        title: 'please add date!',
        confirmButtonText: 'Ok'
      })
    }
    const data = {
      studentId:date.studentId,
      date:date.date
    }; 
    const result = await axios.post(`${baseUrl}/${scheduleDate}`, JSON.stringify(data), config);
    if (result.data === null) {
      alert("no data found.")
  } 
    if (result.data.status === 201) {
        localStorage.setItem('date', result.data)
  
        Swal.fire({
          title: 'Successfull!',
          confirmButtonText: 'Ok'
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/attend");
          }
        })
      }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    await handleChange()

}

const handleDateChange = (event, studentId) => { 
  const updatedDate = { ...date }; 
  updatedDate[studentId] = event.target.value;
  updatedDate["date"] = event.target.value;
  updatedDate["studentId"] = studentId; 
  setDate(updatedDate);  
};



  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  }


  useEffect(() => {
    if(isMounted.current){
    const tableList = async () => {  
      const login_user_id = JSON.parse(userDataString);
      const data = {
        user_id:login_user_id.user_id,
        isAdmin: JSON.parse(userDataisAdmin).isAdmin 
      };   
   
      const result = await axios.post(`${baseUrl}/${enterStudentDetail}`, JSON.stringify(data), config)
      if (result.data.data === null) {
       console.log("data not found")
    }else{
      setData(result.data.data);

    }
      if (result.data.status === 400) {
        console.log("data not found")
    }
    
    }
    isMounted.current = false;
    tableList()
  }
  }, [])

  return (

    <div className="container-fluid ">

      <div className="row">
        <div className="col-sm-2"></div>
        <div className="col-sm-10">
          {/* main navbar */}
          <Navbar />

          {/* totalbox */}
          <Total />
          <div className="container-fluid mt-5" >
            <h3 className="table-Title">Schedule</h3>
            <Table responsive>
              <thead className="table-head">
                <tr>
                  <th>SR</th>
                  <th>DATE</th>
                  <th>NAME</th>
                  <th>EMAIL ID</th>
                  <th>MOBILE NUMBER</th>
                  <th>AREA</th>
                  <th>EDUCATION</th>
                  <th>SOURCE</th>
                  <th>DATE</th>
                  <th>SUBMIT</th>
                </tr>
              </thead>
              <tbody>

                {Object.values(data).map((val, key) => { 
                  return <tr key={key}>
                    <td>{key + 1}</td>
                    <td>{new Date(val.createdAt).toLocaleDateString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric' })}</td>
                                                
                    {/* <td>{val.createdAt}</td> */}
                    <td>{val.name}</td>
                    <td>{val.email}</td>
                    <td>{val.mobile}</td>
                    <td>{val.address}</td>
                    <td>{val.education}</td>
                    <td>{val.source}</td>
                    <td>
                      <input type="date" 
                      className="dateInput"
                       value={date[val._id] || ''} 
                       onChange={(e) => handleDateChange(e,val._id)}
                       />
                    </td>
                   <td>
                   <input className="btn  d-flex justify-content-around Schedule-btn"
                                type="submit"

                                onClick={(e) => handleSubmit(e,val._id)}
                                value="Submit" />
                   </td>
                  </tr>
                })}



              </tbody>
            </Table>



          </div>

        </div>
      </div>


    </div>
  )
}

export default Schedule