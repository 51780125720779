
const userDataString = localStorage.getItem('login_user_data');
const loginUserData = JSON.parse(userDataString); 

function middlenav() {
  return (
   <div className='container-fluid mb-2'>
 <div className="middle-nav">
    <p>current date</p>
    <h1>Welcome back,  <span>{loginUserData.email}</span></h1>
    {/* <h1>Welcome back,  <span>Avani</span></h1> */}
    <p>Always stay updated in your student portal</p>
 </div>
   </div>
  );
}

export default middlenav;