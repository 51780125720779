import React from "react";
import Table from 'react-bootstrap/Table';
import { AiOutlineCalendar } from "react-icons/ai";
import Navbar from './navbar.js';
import Total from './total.js';
import { useNavigate } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASE_URL;
const CourseEndPoint = process.env.REACT_APP_COURSE_ENDPOINT;
const calActionEndPoint = process.env.REACT_APP_CALLACTION_ENDPOINT;
const userDataString = localStorage.getItem('login_user_id');

const CallAction = () => {
    const navigate = useNavigate();

    const [data, setData] = useState([])
    const isMounted = useRef(true)
    const [selectedAction, setSelectedAction] = useState(data ? new Array(data.length).fill('') : []);
    const [narration, setNarration] = useState('')
    const userDataString = localStorage.getItem('login_user_id');
    const userDataisAdmin= localStorage.getItem('login_user_is_admin');

    const options = [
        "F-1",
        "F-2",
        "F-3",
        "positive",
        "enroll",
        "reject"
    ];

    const onOptionChangeHandler = (event, rowIndex) => {
        const updatedSelectedAction = [...selectedAction];
        updatedSelectedAction[rowIndex] = event.target.value;
        setSelectedAction(updatedSelectedAction);
    };
    const handleNarrationChange = (event, studentId) => {
        const updatedNarration = { ...narration };
        updatedNarration[studentId] = event.target.value;
        setNarration(updatedNarration);
    };

    const config = {
        headers: {
            'Content-Type': 'application/json',
        }
    }
    useEffect(() => {
        if (isMounted.current) {

            const tableList = async () => {
                try {
                    const login_user_id = JSON.parse(userDataString);  
                    const data = {
                        user_id:login_user_id.user_id,
                        isAdmin: JSON.parse(userDataisAdmin).isAdmin 
                      };  
                    
                    const result = await axios.post(`${baseUrl}/${CourseEndPoint}`,JSON.stringify(data) ,config);
                    const fetchedData = result.data.data || [];
                    setData(fetchedData);
                } catch (error) {
                    console.log("Error fetching data:", error);
                }
            };
            isMounted.current = false
            tableList();
        }

    }, []);

    const handleChange = async (studentId, rowIndex) => {

        const narrationText = narration[studentId];
        const selectedActionValue = selectedAction[rowIndex];
        // const userDataString = localStorage.getItem('login_user_id');

        const data = {
            studentId: studentId,
            narration: narrationText,
            action: selectedActionValue
        }
        if (!data.action) {
            alert("action is not define")
        } else {
            const result = await axios.post(`${baseUrl}/${calActionEndPoint}`, JSON.stringify(data), config);

            if (result.data === null) {
                alert("no data found.")
            }
            if (result.data.status === 201) {
                localStorage.setItem('student', result.data)
                alert("action")
                window.location.reload()

                if (result.isConfirmed) {
                    navigate("/schedule");
                }
            } else {
                alert("please add action")
            }
        }
    }
    return (

        <div className="container-fluid">

            <div className="row">
                <div className="col-sm-2"></div>
                <div className="col-sm-10">
                    {/* main navbar */}
                    <Navbar />

                    {/* totalbox */}
                    <Total />
                    <div className="container-fluid mt-5" >
                        <h3 className="table-Title">Call & Action</h3>
                        {/* <div> <input type='search ' /></div> */}
                        <Table responsive>
                            <thead className="table-head">
                                <tr>
                                    <th>SR</th>
                                    <th>DATE</th>
                                    <th>NAME</th>
                                    <th>EMAIL ID</th>
                                    <th>MOBILE NUMBER</th>
                                    <th>AREA</th>
                                    <th>EDUCATION</th>
                                    <th>SOURCE</th>
                                    <th>COURSE</th>
                                    <th>NARRATION</th>
                                    <th>ACTION</th>
                                    <th>SUBMIT</th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* {Object.values(data).map((val, key) => {
                                    const studentId = val.studentId[0];
                                    if (studentId) {
                                        return (
                                            <tr key={key}>
                                                <td>{key + 1}</td>
                                                <td>{new Date(val.createdAt).toLocaleDateString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric' })}</td>
                                                <td>{studentId.name}</td>
                                                <td>{studentId.email}</td>
                                                <td>{studentId.mobile}</td>
                                                <td>{studentId.address}</td>
                                                <td>{studentId.education}</td>
                                                <td>{studentId.source}</td>
                                                <td>{val.course}</td>
                                                <td>
                                                    <input
                                                        className="narration"
                                                        type="text"
                                                        value={narration[studentId._id] || ''}
                                                        onChange={(e) => handleNarrationChange(e, studentId._id)}
                                                    />
                                                </td>
                                                <td>
                                                    <select className='course' onChange={(event) => onOptionChangeHandler(event, key)}>
                                                        <option>Action</option>
                                                        {options.map((option, index) => {
                                                            return (
                                                                <option className="attend" key={index} value={option}>
                                                                    {option}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                </td>
                                                <td>
                                                    <button type="button" className="btn btn-outline-primary Schedule-btn" onClick={() => handleChange(studentId._id, key)}>Submit</button>
                                                </td>
                                            </tr>
                                        );
                                    } else {
                                        // Handle undefined data (optional)
                                        console.log(`Undefined data at index ${key}:`, val);
                                        return null; // You can choose to skip or handle undefined data as you prefer
                                    }
                                })} */}


                                {Object.values(data).map((val, key) => {
                                    const studentId = val.studentId[0];
                                    // console.log(studentId)
                                    return <tr key={key}>
                                        <td>{key + 1}</td>
                                        <td>{val.createdAt}</td>
                                        <td>{studentId.name}</td>
                                        <td>{studentId.email}</td>
                                        <td>{studentId.mobile}</td>
                                        <td>{studentId.address}</td>
                                        <td>{studentId.education}</td>
                                        <td>{studentId.source}</td>
                                        <td>{val.course}</td>
                                        <td>
                                            <input
                                                className="narration"
                                                type="text"
                                                value={narration[studentId._id] || ''}
                                                onChange={(e) => handleNarrationChange(e, studentId._id)}
                                            />
                                        </td>
                                        <td>
                                            <select className='course'
                                                onChange={(event) => onOptionChangeHandler(event, key)}>
                                                <option>Action</option>
                                                {options.map((option, index) => {
                                                    return (
                                                        <option className="attend" key={index} value={option}>
                                                            {option}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </td>
                                        <td> <button type="button" className="btn btn-outline-primary Schedule-btn"
                                            onClick={() => handleChange(studentId._id, key)}>Submit</button> </td>
                                    </tr>

                                })}
                            </tbody>
                        </Table>



                    </div>
                </div>
            </div>




        </div>
    )
}

export default CallAction