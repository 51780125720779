/* App.js */
import React, { Component,useState, useEffect  } from 'react';
import CanvasJSReact from '@canvasjs/react-charts';

import axios from 'axios';


const baseUrl = process.env.REACT_APP_BASE_URL;
const AttendEndPoint = process.env.REACT_APP_SCHEDULE_AFTER_ADD_DATE_ENDPOINT;
const enterStudentDetail = process.env.REACT_APP_STUDENTS_ENDPOINT;
// const ScheduleEndPoint = process.env.REACT_APP_SCHEDULE_ENDPOINT;
const calActionEndPoint = process.env.REACT_APP_GETCALLACTION_ENDPOINT
const CourseEndPoint = process.env.REACT_APP_COURSE_ENDPOINT;
const userDataString = localStorage.getItem('login_user_id');
const userDataisAdmin= localStorage.getItem('login_user_is_admin');
 
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
const Piechart = () => {
    const [total, setTotal] = useState([])
    const [callAction, setCallAction] = useState([])
    const [attendTotal, setAttendTotal] = useState([])
    const [phone1, setPhone1] = useState([]);
    const [phone2, setPhone2] = useState([]);
    const [phone3, setPhone3] = useState([]);
    const [positive, setPositive] = useState([]);
    const [enroll, setEnroll] = useState([]);
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    useEffect(() => {
        const tableList = async () => {
            const login_user_id = JSON.parse(userDataString);  
        const data = {
            user_id:login_user_id.user_id,
            isAdmin: JSON.parse(userDataisAdmin).isAdmin 
          };  
        
            const result = await axios.post(`${baseUrl}/${enterStudentDetail}`,JSON.stringify(data), config)

            if (result.data.data !== null) {
                setTotal(result.data.data);
            }

            const callAction = await axios.post(`${baseUrl}/${CourseEndPoint}`,JSON.stringify(data), config);
            if (callAction.data.data !== null) {
                setCallAction(callAction.data.data);
            }

            const attendTotal = await axios.post(`${baseUrl}/${AttendEndPoint}`,JSON.stringify(data), config)
            if (attendTotal.data.data !== null) {
                setAttendTotal(attendTotal.data.data);
            }

            const query = 'F-1';
            const phone1Total = await axios.post(`${baseUrl}/${calActionEndPoint}?action=${query}`,JSON.stringify(data), config)
            if (phone1Total.data.data !== null) {
                setPhone1(phone1Total.data.data);
            }

            const query1 = 'F-2';
            const phone2Total = await axios.post(`${baseUrl}/${calActionEndPoint}?action=${query1}`,JSON.stringify(data), config)
            if (phone2Total.data.data !== null) {
                setPhone2(phone2Total.data.data);
            }

            const query2 = 'F-3';
            const phone3Total = await axios.post(`${baseUrl}/${calActionEndPoint}?action=${query2}`,JSON.stringify(data), config)
            if (phone3Total.data.data !== null) {
                setPhone3(phone3Total.data.data);
            }

            const query3 = 'positive';
            const positiveTotal = await axios.post(`${baseUrl}/${calActionEndPoint}?action=${query3}`,JSON.stringify(data), config)
            if (positiveTotal.data.data !== null) {
                setPositive(positiveTotal.data.data);
            }

            const query4 = 'enroll';
            const enrollTotal = await axios.post(`${baseUrl}/${calActionEndPoint}?action=${query4}`,JSON.stringify(data), config)
            if (enrollTotal.data.data !== null) {
                setEnroll(enrollTotal.data.data);
            }
        }
        tableList()
    }, [])
    CanvasJS.addColorSet("blueShades",
    [//colorSet Array

   "#FF0000",
    "#CC6633",
    "#FF6600",
    "#FF9900",
    "#FFCC00" ,
    "#FFFF00"              
    ]);
		const options = {
			exportEnabled: true,
			animationEnabled: true,
			title: {
				text: "student stage"
			},
            theme: "dark1",
            colorSet: "blueShades",

			data: [{
				type: "pie",
				startAngle: 75,
				toolTipContent: "<b>{label}</b>: {y}",
              
				showInLegend: "true",
				legendText: "{label}",
				indexLabelFontSize: 16,
				indexLabel: "{label} - {y}",
				dataPoints: [
					{ y: positive.length, label: "Positive" },
					{ y: attendTotal.length, label: "Attend" },
					{ y: phone1.length, label: "F1" },
					{ y: phone2.length, label: "F2" },
					{ y: phone3.length, label: "F3" },
                    { y: enroll.length, label: "Enroll" }
				]
			}]
		}
		return (
		<div className='mt-3'>
			<CanvasJSChart options = {options}/>
		</div>
		);
	}
    export default Piechart;