import React, { Component } from 'react';
import CanvasJSReact from '@canvasjs/react-charts';
import { useEffect, useState } from "react";
import axios from 'axios';


var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const userDataString = localStorage.getItem('login_user_id');
const userDataisAdmin= localStorage.getItem('login_user_is_admin');
const baseUrl = process.env.REACT_APP_BASE_URL;
const admingraph = process.env.REACT_APP_ADMINGRAPH

const AdminLineGraph = () => { 
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);

	const config = {
		headers: {
			'Content-Type': 'application/json'
		}
	}
	
	useEffect(() => {
		const tableList = async () => { 
            // const login_user_id = JSON.parse(userDataString);  
            // const data = {
            //     user_id:login_user_id.user_id,
            //     isAdmin: JSON.parse(userDataisAdmin).isAdmin 
            //   };  
			const result = await axios.get(`${baseUrl}/${admingraph}`, config)
			if (result.data && result.data.data) {

				const formattedData = result.data.data.map(item => ({
					x: item.x, 
					y: item.y, 
				  }));
			console.log(formattedData)
				
				  setData(formattedData);
				  setLoading(false);
				   
			} else {
			console.log("loading fail")
				setLoading(false);
			}
		}		
		tableList()
	}, []) 

	 
	const options = {

		animationEnabled: true,
		theme: "dark1",
		title: {
			text: "students",

		},
		axisX: {
			title:"month",
			valueFormatString: "MMM"
		},
		axisY: {
			title: "student",
			prefix: "",
			gridThickness: 0,
		},
		
		data: [{
			yValueFormatString: "###",
			xValueFormatString: "MMMM",
			type: "spline",
			lineColor:"gold",
			markerColor:"gold",
			dataPoints: data,
		
		}],
	}

	return (
		
				<div className='mt-3'>
					
					<CanvasJSChart options={options}/>
				</div>
				
	

	);
}

export default AdminLineGraph;

