import React from 'react';
import Profile from '../images/profile.jpeg'

const userDataString = localStorage.getItem('login_user_data');
const loginUserData = JSON.parse(userDataString); 
const userImageString = localStorage.getItem('login_user_image');
const loginUserImage = JSON.parse(userImageString);  

function Navbar() { 

  return (
    <div className="container-fluid mt-2 mb-5   ">
      <nav className="navbar  justify-content-between ">
        <form className="form-inline">
        </form>
        <div className="profile">
          <img src={Profile} className="loginImg"/>
          </div>

        {/* <a className="navbar-brand justify-align-center" style={{color:"white" }}>avani@gmail.com</a> */}
        <a className="navbar-brand justify-align-center" style={{color:"white" }}>{loginUserData.email}</a>
      </nav>
     
    </div>
  );
}

export default Navbar;