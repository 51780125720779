import Navbar from './navbar.js'
import Total from './total.js'
import React, { useState, useEffect, useRef } from 'react';

import { useNavigate } from "react-router-dom";
import axios from 'axios';
import auth from '../utils/auth.js';
import Swal from 'sweetalert2';


const baseUrl = process.env.REACT_APP_BASE_URL;
const EntryEndPoint = process.env.REACT_APP_ENTRY_ENDPOINT;
const serialNumberEndPoint = process.env.REACT_APP_SERIAL_NUMBER;
const userDataString = localStorage.getItem('login_user_id');

const Entry = () => {

    const navigate = useNavigate();
    const isMounted = useRef(true)
    const [emailError, setEmailError] = useState(null);
    const [mobileError, setmobileError] = useState(null);

    const [nameError, setnameError] = useState(null);


    const [email, setEmail] = useState('');
    const [date, setDate] = useState('');
    const [name, setName] = useState('');
    const [mobile, setMobile] = useState('');
    const [address, setAddress] = useState('');
    const [education, setEducation] = useState('');
    const [source, setSource] = useState('');
    const [data, setData] = useState([])


    const options = [
        "linkdin",
        "indeed",
        "whatsApp",
        "reference"];


    const onOptionChangeHandler = (event) => {
        setSource(event.target.value);

    };


    const token = auth()


    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
        }
    }

    function getFormattedDate() {
        const today = new Date();
        const year = today.getFullYear();
        let month = today.getMonth() + 1;
        if (month < 10) {
            month = `0${month}`;
        }
        let day = today.getDate();
        if (day < 10) {
            day = `0${day}`;
        }
        return `${year}-${month}-${day}`;
    }

    // for serial number
    useEffect(() => {
        if (isMounted.current) {
            const tableList = async () => {
                const login_user_id = JSON.parse(userDataString);
                    const data = {
                        user_id: login_user_id.user_id,
                    };
                const result = await axios.post(`${baseUrl}/${serialNumberEndPoint}`, JSON.stringify(data), config)
                if (result.data.data === null || result.data.status === 400) {
                    console.log("data not found")
                } else {
                    setData(result.data.data);

                }
               
                setData(result.data.data);
            }
            isMounted.current = false;
            tableList()
        }
        setDate(getFormattedDate());
    }, [])



    const handleSubmit = async (e) => {

        e.preventDefault();

        if (!name) {
            setnameError('name is required')
        }

        if (!validateEmail(email)) {
            setEmailError('Invalid email address.');
            return;
        }
        if (!validatePhoneNumber(mobile)) {
            setmobileError('Invalid phoneNumber.');
            return;
        }

        await handleChange()

    }

    const validateEmail = (email) => {
        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(email);
    }
    const validatePhoneNumber = (mobile) => {
        var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\. ]?[0-9]{3}[-\s\. ]?[0-9]{4,6}$/im;
        return re.test(mobile);
    }

    const handleChange = async (e) => {

        const data = {
            date: date,
            name: name,
            email: email,
            mobile: mobile,
            address: address,
            education: education,
            source: source,
        };

        const result = await axios.post(`${baseUrl}/${EntryEndPoint}`, JSON.stringify(data), config);

        if (result.data.status === 200) {
            localStorage.setItem('student', result.data)

            Swal.fire({
                title: 'Successfull!',
                confirmButtonText: 'Ok'
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate("/schedule");
                }
            })
        }
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-2"></div>
                <div className="col-sm-10">
                    {/* main navbar */}
                    <Navbar />

                    {/* totalbox */}
                    <Total />

                    <div className='m-3 pb-4 entry-main'>
                        <div className="mt-3 ms-3">
                            <h1 className="table-Title">Entry</h1>
                        </div>
                        <form onSubmit={(e) => handleSubmit(e)}>
                            <div className="row">
                                <div className="col-sm-3 m-2">
                                    <div><input className="entry" type="text" value={data} placeholder="Serial Number" readOnly /></div>
                                </div>
                                <div className="col-sm-3 m-2">

                                    <div><input className="entry" type="date" value={date} onChange={(e) => setDate(e.target.value)} placeholder="Date" />
                                    </div>
                                </div>
                                <div className="col-sm-3 m-2">
                                    <div><input className="entry" type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Name" /></div>
                                    {nameError && <h2 style={{ color: 'red', fontSize: "10px" }}>{nameError}</h2>}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-3 m-2">
                                    <div><input className="entry" type="text" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email Id" /></div>
                                    {emailError && <h2 style={{ color: 'red', fontSize: "10px" }}>{emailError}</h2>}
                                </div>
                                <div className="col-sm-3 m-2">
                                    <div>  <input type="tel" className="entry" pattern="[0-9]{10}" value={mobile} onChange={(e) => setMobile(e.target.value)} placeholder="Mobile Number" required /> </div>
                                    {mobileError && <h2 style={{ color: 'red', fontSize: "10px" }}>{mobileError}</h2>}
                                </div>
                                <div className="col-sm-3 m-2">
                                    <div><input className="entry" type="text" value={address} onChange={(e) => setAddress(e.target.value)} placeholder="Address" /></div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-3 m-2">
                                    <div><input className="entry" type="text" value={education} onChange={(e) => setEducation(e.target.value)} placeholder="Education" /></div>
                                </div>
                                <div className="col-sm-3 m-2 ">
                                    <select className='entry' onChange={onOptionChangeHandler}>
                                        <option value="" disabled selected>
                                            Select Source
                                        </option>
                                        {options.map((option, index) => {
                                            return (
                                                <option key={index}>
                                                    {option}
                                                </option>
                                            );
                                        })}
                                    </select>

                                </div>

                                <div className="col-sm-3 m-2"></div>
                            </div>
                            <div className="row mt-5">

                                <div className="d-flex justify-content-around">

                                    <input className="btn  d-flex justify-content-around generate-btn"
                                        type="submit"
                                        onClick={(e) => handleSubmit(e)}
                                        value="Generate schedule" />
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </div>


        </div>


    )
}
export default Entry
