import React,{useEffect} from 'react';
import { HashRouter as BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import Login from "./pages/login.js";
import Dash from './component/dashboard';
import Entry from "./component/ent.js";
import Schedule from "./component/schedule.js";
import Attend from './component/attend.js';
import CallAction from './component/callAction.js';
import Positive from './component/positive.js'
import Enroll from './component/enroll.js';
import ProtectedRoutes from './pages/protectedroute';
import Phone1 from './component/phone-1';
import Phone2 from './component/phone-2';
import Phone3 from './component/phone-3';
import Reject from './component/reject';
import ErrorPage from './component/error';

function App() {


  return (
    <>


      <BrowserRouter>
    
        <Routes>

          <Route  element={<ProtectedRoutes />}>
          <Route path='*' element={<ErrorPage/>} />
            <Route path='/dashboard' element={<Dash />} />
            <Route path='/entry' element={<Entry />} exact />
            <Route path='/attend' element={<Attend />} />
            <Route path='/schedule' element={<Schedule />} />
            <Route path='/callAction' element={<CallAction />} />
            <Route path='/positive' element={<Positive />} />
            <Route path="/Phone1" element={<Phone1 />} />
            <Route path="/Phone2" element={<Phone2 />} />
            <Route path="/Phone3" element={<Phone3 />} />
            <Route path="/enroll" element={<Enroll />} />
            <Route path="/reject" element={<Reject />} />
          </Route>
          <Route path='/' exact element={<Login />} />
          {/* <Route path="/" element={<Logout/>}/> */}

        </Routes>

      </BrowserRouter>  
    </>
  );
}

export default App;
