import React from "react";
import Table from 'react-bootstrap/Table';
import { AiOutlineCalendar } from "react-icons/ai";
import Navbar from './navbar.js';
import Total from './total.js';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { useEffect, useState, useRef } from "react";

const baseUrl = process.env.REACT_APP_BASE_URL;
const AttendEndPoint = process.env.REACT_APP_ATTEND_ENDPOINT;
const ScheduleEndPoint = process.env.REACT_APP_SCHEDULE_AFTER_ADD_DATE_ENDPOINT;
const userDataString = localStorage.getItem('login_user_id');
const userDataisAdmin= localStorage.getItem('login_user_is_admin');

const Attend = () => {

    const navigate = useNavigate();
    const isMounted = useRef(true);

    const [data, setData] = useState([])
    const [selectedFilterDate, setSelectedFilterDate] = useState('');

    const [date, setDate] = useState('')

    let data_length = [];

    if (data.length != null) {
        data_length = new Array(data.length).fill('');
    } else {
        data_length = [];
    }

    const [selectedCourses, setSelectedCourses] = useState(data_length);


    const onOptionChangeHandler = (event, rowIndex) => {
        const updatedSelectedCourses = [...selectedCourses];
        updatedSelectedCourses[rowIndex] = event.target.value;
        setSelectedCourses(updatedSelectedCourses);
    };

    const handleDateChange = (event, studentId) => {
        const updatedDate = { ...date };
        updatedDate[studentId] = event.target.value;
        setDate(updatedDate);
    };
    const options = [
        "PHP",
        "nodeJS",
        "UI/UXS",
        "HR"];


    const config = {
        headers: {
            'Content-Type': 'application/json',

        }
    }

    useEffect(() => {
        if (isMounted.current) {

            const fetchData = async () => {


                try {
                    const login_user_id = JSON.parse(userDataString);  
                    const data = {
                        user_id:login_user_id.user_id,
                        isAdmin: JSON.parse(userDataisAdmin).isAdmin 
                      };  
                    const result = await axios.post(`${baseUrl}/${ScheduleEndPoint}`, JSON.stringify(data), config);
                    if (result.data.status === 400 || result.data.data === null) {
                        console.log("Data not found");
                    } else {
                        const newData = result.data.data || [];
                        const newSelectedCourses = new Array(newData.length).fill('');
                        setSelectedCourses(newSelectedCourses);
                        setData(newData);
                    }
                } catch (error) {
                    console.error("Error fetching data: ", error);
                }
            };
            isMounted.current = false;
            fetchData();
        }


    }, [])

    const handleChange = async (id, rowIndex) => {
        const DateText = date[id];
        const selectedCourse = selectedCourses[rowIndex];

        if (!selectedCourse) {
            alert("Please select a course for this row");
            return;
        }
        if (!DateText) {
            alert("Please add date for this row");
            return;
        }

        const data = {
            studentId: id,
            course: selectedCourse,
            date: DateText
        };

        if (!data.course) {
            console.log("course is not define")
        } else {
            const result = await axios.post(`${baseUrl}/${AttendEndPoint}`, JSON.stringify(data), config);
            
            if (result.data.status === 201) {
                alert("attend")
                window.location.reload()
                localStorage.setItem('student', result.data)
                if (result.isConfirmed) {
                    navigate("/callAction");
                }
            } else {
                console.log("please add course")
            }
        }
    }


    return (
        <div>
            <div className="container-fluid">



                <div className="row">
                    <div className="col-sm-2"></div>
                    <div className="col-sm-10">
                        {/* main navbar */}
                        <Navbar />

                        {/* totalbox */}
                        <Total />

                        <div className="container-fluid mt-5">
                            <div>
                                <h3 className="table-Title" style={{ display: "inline-block" }}>Attend</h3>
                                <div className="filter">
                                    <label>Filter by Date:</label>
                                    <input
                                        type="date"
                                        className="filterDate "
                                        value={selectedFilterDate}
                                        onChange={(e) => setSelectedFilterDate(e.target.value)}
                                    />
                                </div>
                            </div>

                            <Table responsive>
                                <thead className="table-head">
                                    <tr>
                                        <th>SR</th>
                                        <th>DATE</th>
                                        <th>NAME</th>
                                        <th>EMAIL ID</th>
                                        <th>MOBILE NUMBER</th>
                                        <th>AREA</th>
                                        <th>EDUCATION</th>
                                        <th>SOURCE</th>
                                        <th>COURSE</th>
                                        <th>DATE</th>
                                        <th>ATTEND</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {Object.values(data).map((val, key) => {
                                        const studentId = val.studentId[0];

                                        const dateMatch = !selectedFilterDate || new Date(val.date).toLocaleDateString() === new Date(selectedFilterDate).toLocaleDateString();
                                        if (dateMatch && studentId != undefined) {
                                            return (
                                                <tr key={key}>
                                                    <td>{key + 1}</td>
                                                    <td>{new Date(val.date).toLocaleDateString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric' })}</td>
                                                    <td>{studentId.name}</td>
                                                    <td>{studentId.email}</td>
                                                    <td>{studentId.mobile}</td>
                                                    <td>{studentId.address}</td>
                                                    <td>{studentId.education}</td>
                                                    <td>{studentId.source}</td>

                                                    <td>
                                                        <select className='course'
                                                            onChange={(event) => onOptionChangeHandler(event, key)}>
                                                            <option>Course</option>
                                                            {options.map((option, index) => {
                                                                return (
                                                                    <option className="attend" key={index} value={option}>
                                                                        {option}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <input type="date"
                                                            className="dateInput"
                                                            style={{ width: "24px" }}
                                                            value={date[studentId._id] || ''}
                                                            onChange={(e) => handleDateChange(e, studentId._id)}
                                                        />
                                                    </td>
                                                    <td> <button type="button"
                                                        className="btn btn-outline-primary Schedule-btn"
                                                        onClick={() => handleChange(studentId._id, key)}
                                                    >Attend</button>
                                                    </td>
                                                </tr>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })}


                                </tbody>
                            </Table>



                        </div>
                    </div>
                </div>


            </div>
        </div>
    )
}

export default Attend;