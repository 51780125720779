import React, { useState, useEffect } from 'react';
import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASE_URL;
const AttendEndPoint = process.env.REACT_APP_SCHEDULE_AFTER_ADD_DATE_ENDPOINT;
const ScheduleEndPoint = process.env.REACT_APP_STUDENTS_ENDPOINT;
const calActionEndPoint = process.env.REACT_APP_GETCALLACTION_ENDPOINT
const CourseEndPoint = process.env.REACT_APP_COURSE_ENDPOINT;

const Total = () => {
    const [total, setTotal] = useState([])
    const [callAction, setCallAction] = useState([])
    const [attendTotal, setAttendTotal] = useState([])
    const [phone1, setPhone1] = useState([]);
    const [phone2, setPhone2] = useState([]);
    const [phone3, setPhone3] = useState([]);
    const [positive, setPositive] = useState([]);
    const [enroll, setEnroll] = useState([]);
    const userDataString = localStorage.getItem('login_user_id');
    const userDataisAdmin= localStorage.getItem('login_user_is_admin');

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }



    useEffect(() => {
        const tableList = async () => {
            const login_user_id = JSON.parse(userDataString);  
        const data = {
            user_id:login_user_id.user_id,
            isAdmin: JSON.parse(userDataisAdmin).isAdmin 
          };  
        
            const result = await axios.post(`${baseUrl}/${ScheduleEndPoint}`, JSON.stringify(data), config)
            if (result.data.data !== null) {
                setTotal(result.data.data);
            }

            const callAction = await axios.post(`${baseUrl}/${CourseEndPoint}`, JSON.stringify(data), config);
            if (callAction.data.data !== null) {
                setCallAction(callAction.data.data);
            }

            const attendTotal = await axios.post(`${baseUrl}/${AttendEndPoint}`, JSON.stringify(data), config)
            // console.log(attendTotal)
            if (attendTotal.data.data !== null) {
                setAttendTotal(attendTotal.data.data);
            }

            const query = 'F-1';
            const phone1Total = await axios.post(`${baseUrl}/${calActionEndPoint}?action=${query}`, JSON.stringify(data), config)
            if (phone1Total.data.data !== null) {
                setPhone1(phone1Total.data.data);
            }

            const query1 = 'F-2';
            const phone2Total = await axios.post(`${baseUrl}/${calActionEndPoint}?action=${query1}`, JSON.stringify(data), config)
            if (phone2Total.data.data !== null) {
                setPhone2(phone2Total.data.data);
            }

            const query2 = 'F-3';
            const phone3Total = await axios.post(`${baseUrl}/${calActionEndPoint}?action=${query2}`, JSON.stringify(data), config)
            if (phone3Total.data.data !== null) {
                setPhone3(phone3Total.data.data);
            }

            const query3 = 'positive';
            const positiveTotal = await axios.post(`${baseUrl}/${calActionEndPoint}?action=${query3}`,JSON.stringify(data), config)
            if (positiveTotal.data.data !== null) {
                setPositive(positiveTotal.data.data);
            }

            const query4 = 'enroll';
            const enrollTotal = await axios.post(`${baseUrl}/${calActionEndPoint}?action=${query4}`,JSON.stringify(data), config)
            if (enrollTotal.data.data !== null) {
                setEnroll(enrollTotal.data.data);
            }
        }
        tableList()
    }, [])





    return (
        <div className='container-fluid'>
            <div className="row">

                <div className="col total-col">
                    <a href='/schedule'>
                        <div className="d-flex justify-content-center total-head">schedule</div>
                        <div className="d-flex justify-content-center total-data">{total.length} </div>
                    </a>
                </div>
                <div className="col total-col">
                    <a href='#/attend'>
                        <div className="d-flex justify-content-center total-head">attend</div>
                        <div className="d-flex justify-content-center total-data">{attendTotal.length}</div>
                    </a>
                </div>
                <div className="col total-col">
                    <a href='#/callAction'>
                        <div className="d-flex justify-content-center total-head">Call&Action</div>
                        <div className="d-flex justify-content-center total-data">{callAction.length}</div>
                    </a>
                </div>
                <div className="col total-col">
                    <a href='#/phone1'>
                        <div className="d-flex justify-content-center total-head">F1</div>
                        <div className="d-flex justify-content-center total-data">{phone1.length}</div>
                    </a>
                </div>
                <div className="col total-col">
                    <a href='#/phone2'>
                        <div className="d-flex justify-content-center total-head">F2</div>
                        <div className="d-flex justify-content-center total-data">{phone2.length}</div>
                    </a>
                </div>
                <div className="col total-col">
                    <a href='#/phone3'>
                        <div className="d-flex justify-content-center total-head">F3</div>
                        <div className="d-flex justify-content-center total-data">{phone3.length}</div>
                    </a>
                </div>
                <div className="col total-col">
                    <a href='#/positive'>
                        <div className="d-flex justify-content-center total-head">Positive</div>
                        <div className="d-flex justify-content-center total-data">{positive.length}</div>
                    </a>
                </div>
                <div className="col total-col">
                    <a href='#/enroll'>
                        <div className="d-flex justify-content-center total-head">Enroll</div>
                        <div className="d-flex justify-content-center total-data">{enroll.length}</div>
                    </a>
                </div>

            </div>
        </div>
    );
}

export default Total;